@tailwind base;
@tailwind components;
@tailwind utilities;

@keyframes blink {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.3;
  }
}
